;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"dev-5ba04b8-031df243-83fb-474e-bb0c-b6958875f007"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

const SENTRY_EXCLUDED_PATHS = ["/health-check"];

if (process.env.sentryDsn) {
  Sentry.init({
    tunnel: process.env.sentryTunnel,
    dsn: process.env.sentryDsn,
    environment: process.env.environment,
    tracesSampler: (samplingContext) => {
      const path = samplingContext.location?.pathname;
      if (SENTRY_EXCLUDED_PATHS.includes(path)) {
        return 0.0;
      }

      return 0.2;
    },
    beforeSendTransaction(event) {
      if (event.user) {
        delete event.user.ip_address;
        delete event.user.email;
      }
      return event;
    },
    beforeSend(event) {
      if (event.user) {
        delete event.user.ip_address;
        delete event.user.email;
      }
      return event;
    },
    ignoreErrors: [
      /.*ReaderArticleFinderJS\.adoptableArticle.*/,
      "this.Eg.close is not a function",
      /.*Can't find variable: gmo.*/,
    ],
  });
}
